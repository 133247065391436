import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";

import {
  Footer as FooterComponent,
  Content,
  Column,
  LogoContent,
  Logo,
  Copyright,
  Social,
  Links,
} from "./FooterStyles";
import { Link } from "gatsby";

const Footer: React.FC = () => {
  return (
    <FooterComponent>
      <Content className="footer-content">
        <Column className="footer-column">
          <LogoContent className="footer-logoContent">
            <Logo className="footer-logo">
              <StaticImage
                src="../../assets/svg/logo.svg"
                alt="Guarani Sistemas"
                width={235}
                placeholder="blurred"
              />
            </Logo>
            <Copyright className="footer-text">
              © {new Date().getFullYear()}. Todos os direitos reservados.
            </Copyright>
            <Social className="footer-social">
              <a
                href="https://www.facebook.com/guaranisistemas"
                target="_blank"
              >
                <FaFacebookF />
              </a>

              <a
                href="https://www.instagram.com/guaranisistemas/?hl=pt-br"
                target="_blank"
              >
                <FaInstagram />
              </a>

              <a href="https://twitter.com/guaranisistemas" target="_blank">
                <FaTwitter />
              </a>

              <a
                href="https://www.linkedin.com/company/guarani-sistemas"
                target="_blank"
              >
                <FaLinkedin />
              </a>

              <a href="https://www.youtube.com/guaranisistemas" target="_blank">
                <FaYoutube />
              </a>
            </Social>
          </LogoContent>
        </Column>

        <Column className="footer-column">
          <Links className="footer-links">
            <ul>
              <li className="header">Sobre</li>
              <li><Link to="/sobre">Quem somos</Link></li>
              <li><a href="https://rd.guaranisistemas.com.br/vagas" target="_blank">Trabalhe conosco</a></li>
            </ul>
            <ul>
              <li className="header">Produtos</li>
              <li><Link to="/produtos/erp">Guarani ERP</Link></li>
              <li><Link to="/produtos/afv">Guarani AFV</Link></li>
              <li><Link to="/produtos/b2b">Guarani B2B</Link></li>
              <li><Link to="/produtos/bi">Guarani BI</Link></li>
            </ul>
            <ul>
              <li className="header"><a href="https://academia.guaranisistemas.com.br/" target="_blank">Academia</a></li>
            </ul>
            <ul>
              <li className="header"><a href="https://blog.guaranisistemas.com.br/" target="_blank">Blog</a></li>
            </ul>
            <ul>
              <li className="header">Suporte</li>
              <li><a href="https://guaranisistemas.atlassian.net/servicedesk/customer/portals" target="_blank">Abra seu ticket</a></li>
              <li><a href="https://wa.me/551633755340?text=Ol%C3%A1,%20quero%20falar%20com%20a%20Guarani%20Sistemas" target="_blank">Chat WhatsApp</a></li>
            </ul>
          </Links>
        </Column>
      </Content>
    </FooterComponent>
  );
};

export { Footer };
