import styled from "styled-components";

export const CustomModalContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;

  &.open {
    display: flex;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
`;

export const CustomModalContent = styled.div`
  position: relative;
  z-index: 1;
`;