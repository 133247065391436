import { createGlobalStyle } from "styled-components";
import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/600.css";

export const GlobalStyle = createGlobalStyle`
  body, html {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    overflow-x: hidden;
  }

  body, input, textarea, button, a, select {
    font-family: 'Poppins';
  }

  a {
    text-decoration: none;
  }
`;