import React, { HTMLAttributes } from "react";

import { Button as ButtonComponent } from "./ButtonStyles";

interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  label: string;
  variant?: "primary" | "secondary";
}

const Button: React.FC<ButtonProps> = ({label, variant = "primary", ...props}) => {
  return(
    <ButtonComponent {...props} variant={variant}>{label}</ButtonComponent>
  )
}

export { Button }