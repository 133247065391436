import React, { useContext, useEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { globalHistory } from "@reach/router";

import {
  Header as HeaderComponent,
  HeaderContainer,
  LogoContainer,
  HeaderNavigation,
  HeaderButton,
  HeaderHamburguer,
  MenuMobile,
  MenuMobileContainer,
  MenuMobileLinks,
  MenuMobileButtonContainer,
} from "./HeaderStyles";
import { Button } from "../Button";
import ModalContext from "../../context/ModalContext";
import { FormModal } from "../FormModal";
import { CustomModal } from "../CustomModal";

type MenuMobileType = "sobre" | "produtos" | "suporte" | null;

const Header: React.FC = () => {
  const [menuMobileActive, setMenuMobileActive] =
    useState<MenuMobileType>(null);
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  const { isOpen, setModalOpen } = useContext(ModalContext);

  const handleHamburguer = (event: React.MouseEvent<HTMLDivElement>) => {
    setShowMobileMenu(!showMobileMenu);
  };

  const handleMobileMenu = (type: MenuMobileType) => {
    if (menuMobileActive === type) {
      setMenuMobileActive(null);
    } else {
      setMenuMobileActive(type);
    }
  };

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        setShowMobileMenu(false);
        setMenuMobileActive(null);
      }
    })
  }, [showMobileMenu]);

  return (
    <HeaderComponent>
      <HeaderContainer>
        <LogoContainer>
          <Link to="/">
            <StaticImage
              src="../../assets/svg/logo.svg"
              alt="Guarani Sistemas"
              width={163}
              height={41}
              placeholder="blurred"
            />
          </Link>
        </LogoContainer>

        <HeaderNavigation>
          <span tabIndex={0}>
            Sobre
            <ul tabIndex={1}>
              <li>
                <Link to="/sobre">Quem somos</Link>
              </li>
              <li><a href="https://rd.guaranisistemas.com.br/vagas" target="_blank">Trabalhe conosco</a></li>
            </ul>
          </span>
          <span tabIndex={0}>
            Produtos
            <ul tabIndex={1}>
              <li>
                <Link to="/produtos/erp">Guarani ERP</Link>
              </li>
              <li>
                <Link to="/produtos/afv">Guarani AFV</Link>
              </li>
              <li>
                <Link to="/produtos/b2b">Guarani B2B</Link>
              </li>
              <li>
                <Link to="/produtos/bi">Guarani BI</Link>
              </li>
            </ul>
          </span>
          <a href="https://academia.guaranisistemas.com.br/" target="_blank">Academia</a>
          <a href="https://blog.guaranisistemas.com.br/" target="_blank">Blog</a>
          <span tabIndex={0}>
            Suporte
            <ul tabIndex={1}>
              <li><a href="https://guaranisistemas.atlassian.net/servicedesk/customer/portals" target="_blank">Abra seu ticket</a></li>
              <li><a href="https://wa.me/551633755340?text=Ol%C3%A1,%20quero%20falar%20com%20a%20Guarani%20Sistemas" target="_blank">Chat WhatsApp</a></li>
            </ul>
          </span>
        </HeaderNavigation>

        <HeaderButton onClick={() => setModalOpen(true)}>
          Solicite uma demonstração
        </HeaderButton>
        <HeaderHamburguer onClick={handleHamburguer} className={showMobileMenu ? 'open' : ''} tabIndex={0}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </HeaderHamburguer>
      </HeaderContainer>

      {showMobileMenu && (
        <MenuMobile>
          <MenuMobileContainer>
            <span
              onClick={() => handleMobileMenu("sobre")}
              className={menuMobileActive === "sobre" ? "active" : ""}
            >
              Sobre
            </span>
            {menuMobileActive === "sobre" && (
              <MenuMobileLinks>
                <Link to="/sobre">Quem somos</Link>
                <a href="https://rd.guaranisistemas.com.br/vagas" target="_blank">Trabalhe conosco</a>
              </MenuMobileLinks>
            )}
            <span
              onClick={() => handleMobileMenu("produtos")}
              className={menuMobileActive === "produtos" ? "active" : ""}
            >
              Produtos
            </span>
            {menuMobileActive === "produtos" && (
              <MenuMobileLinks>
                <Link to="/produtos/erp">Guarani ERP</Link>
                <Link to="/produtos/afv">Guarani AFV</Link>
                <Link to="/produtos/b2b">Guarani B2B</Link>
                <Link to="/produtos/bi">Guarani BI</Link>
              </MenuMobileLinks>
            )}
            <a href="https://academia.guaranisistemas.com.br/" target="_blank" onClick={() => handleMobileMenu(null)}>
              Academia
            </a>
            <a href="https://blog.guaranisistemas.com.br/" target="_blank" onClick={() => handleMobileMenu(null)}>
              Blog
            </a>
            <span
              onClick={() => handleMobileMenu("suporte")}
              className={menuMobileActive === "suporte" ? "active" : ""}
            >
              Suporte
            </span>
            {menuMobileActive === "suporte" && (
              <MenuMobileLinks>
                <a href="https://guaranisistemas.atlassian.net/servicedesk/customer/portals" target="_blank">Abra seu ticket</a>
                <a href="https://wa.me/551633755340?text=Ol%C3%A1,%20quero%20falar%20com%20a%20Guarani%20Sistemas" target="_blank">Chat WhatsApp</a>
              </MenuMobileLinks>
            )}
          </MenuMobileContainer>

          <MenuMobileButtonContainer>
            <Button
              label="Solicite uma demonstração"
              onClick={() => setModalOpen(true)}
            />
          </MenuMobileButtonContainer>
        </MenuMobile>
      )}

      <CustomModal isOpen={isOpen} onClose={() => setModalOpen(false)}>
        <FormModal onClose={() => setModalOpen(false)} />
      </CustomModal>
    </HeaderComponent>
  );
};

export { Header };
