import React, { useContext, useEffect } from "react";
import Modal from "react-modal";
import { FaTimes } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Button } from "../Button";

import {
  ModalComponent,
  ModalForm as ModalFormComponent,
  ModalGrid,
  ModalHeader,
  ModalContent,
  ModalField as ModalFieldComponent,
  ErrorMessage,
  ModalButtonContainer,
} from "./FormModalStyles";
import { toast } from "react-toastify";

import ModalContext from "../../context/ModalContext";
import { ModalField } from "./ModalField";

interface FormModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export interface FormFields {
  produto: string;
  nome: string;
  email: string;
  phone: string;
  empresa: string;
  segmento: string;
  mensagem?: string;
}

const overlayStyle = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
};

const schema = yup.object().shape({
  produto: yup.string(),
  nome: yup.string().required("Campo obrigatório"),
  email: yup.string().required("Campo obrigatório").email("Email inválido"),
  phone: yup
    .string()
    .required("Campo obrigatório")
    .min(14, "Telefone inválido"),
  empresa: yup.string().required("Campo obrigatório"),
  segmento: yup.string(),
  mensagem: yup.string().nullable(),
});

Modal.setAppElement("#___gatsby");

const FormModal: React.FC<FormModalProps> = ({ isOpen, onClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<FormFields>({ resolver: yupResolver(schema) });
  const { activeProduct } = useContext(ModalContext);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    toast.success(({ closeToast, toastProps }) => (
      <div>
        <p style={{margin: 0, marginBottom: 5, fontSize: 14}}><strong>Obrigado pelo interesse!</strong></p>
        <p style={{margin: 0, fontSize: 13}}>Em breve entraremos em contato.</p>
      </div>
    ));

    handleClose();
  };

  const handleClose = () => {
    reset({
      email: "",
      empresa: "",
      mensagem: "",
      nome: "",
      phone: "",
    });

    onClose();
  };

  useEffect(() => {
    setValue("produto", activeProduct);
  }, [activeProduct]);

  return (
    <ModalComponent>
      <ModalHeader>
        <h3>Agende uma apresentação</h3>
        <FaTimes onClick={handleClose} />
      </ModalHeader>

      <ModalContent>
        <form onSubmit={(event) => onSubmit(event)} id="solicite-demo" name="solicite-demo">
          <ModalGrid>
            <ModalFieldComponent>
              <label>Produto</label>
              <select required {...register("produto")}>
                <option value="erp">Guarani ERP</option>
                <option value="afv">Guarani AFV</option>
                <option value="b2b">Guarani B2B</option>
                <option value="bi">Guarani BI</option>
                <option value="cloud">Guarani Cloud</option>
                <option value="wms">Guarani WMS</option>
                <option value="pcp">Guarani PCP</option>
                <option value="telemarketing">Guarani Telemarketing</option>
              </select>
            </ModalFieldComponent>

            <ModalFieldComponent>
              <label>
                Nome <sup>*</sup>
              </label>
              <input type="text" {...register("nome")} required />
            </ModalFieldComponent>

            <ModalFieldComponent>
              <label>
                E-mail <sup>*</sup>
              </label>
              <input type="email" {...register("email")} required />
            </ModalFieldComponent>

            <ModalField label="Telefone / Whatsapp" type="phone" isRequired id="phone" mask="phone" {...register("phone")} />

            <ModalFieldComponent>
              <label>
                Nome da empresa <sup>*</sup>
              </label>
              <input type="text" {...register("empresa")} required />
            </ModalFieldComponent>

            <ModalFieldComponent>
              <label>Segmento</label>
              <select {...register("segmento")} required>
                <option value="industria">
                  Indústria
                </option>
                <option value="importador">
                Importador
                </option>
                <option value="distribuidor">Distribuidor</option>
                <option value="atacadista">Atacadista</option>
                <option value="varejo">Varejo</option>
                <option value="consultoria">Consultoria</option>
              </select>
            </ModalFieldComponent>
          </ModalGrid>

          <ModalFieldComponent>
            <label>Descrição da necessidade</label>
            <textarea {...register("mensagem")} />
          </ModalFieldComponent>

          <ModalButtonContainer>
            <Button type="submit" label="Agendar" variant="secondary" />
          </ModalButtonContainer>
        </form>
      </ModalContent>
    </ModalComponent>
  );
};

export { FormModal };
