import styled from "styled-components";

export const Header = styled.header`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #274b2b;
  z-index: 999;
`;

export const HeaderContainer = styled.div`
  box-sizing: border-box;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 990px) {
    justify-content: space-between;
    height: 70px;
  }
`;

export const LogoContainer = styled.div`
  width: 163px;
  height: 41px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
`;

export const HeaderNavigation = styled.nav`
  flex: 1;
  margin: 0 15px;
  margin-right: 40px;
  display: flex;
  justify-content: flex-end;
  gap: 45px;
  height: 75%;
  box-sizing: border-box;

  @media (max-width: 990px) {
    display: none;
  }

  a,
  span {
    all: unset;
    font-size: 15px;
    text-decoration: none;
    color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 5px;

    /* &:focus {
      box-shadow: 0 0 0 3px rgb(65, 166, 255);
      border-radius: 4px;
    } */

    &::before {
      content: "";
      position: absolute;
      bottom: 25px;
      left: 0;
      width: 0;
      height: 1px;
      background-color: #fff;
      transition: width 0.2s linear;
    }

    &:hover,
    &:focus {
      ul {
        opacity: 1;
        visibility: visible;
      }
    }

    &:hover::before,
    &:focus::before {
      width: 100%;
    }

    ul {
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.2s ease-in;
      list-style: none;
      padding-left: 0;
      background-color: #fff;
      color: #274b2b;
      position: absolute;
      top: 80%;
      min-width: 11rem;

      &:focus {
        opacity: 1;
        visibility: visible;
      }

      li {
        color: #274b2b;
        transition: color 0.2s linear;
        padding: 15px;
        font-size: 14px;

        a {
          text-decoration: none;
          color: #274b2b;
          transition: color 0.2s linear;

          &:hover {
            color: #59a059;
          }
        }

        &:hover {
          color: #59a059;
        }

        &:not(:first-child) {
          padding-top: 0;
        }
      }
    }
  }
`;

export const HeaderButton = styled.button`
  all: unset;
  font-size: 14px;
  color: #fff;
  background-color: #59a059;
  border: 1px solid #59a059;
  padding: 16px 30px;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease-in, border 0.3s ease-in;

  &:hover {
    background-color: transparent;
    border: 1px solid #fff;
  }

  &:focus {
    box-shadow: 0 0 0 3px rgb(65, 166, 255);
  }

  @media (max-width: 990px) {
    display: none;
  }
`;

export const HeaderHamburguer = styled.div`
  display: none;
  width: 22px;
  height: 17px;
  cursor: pointer;
  position: relative;

  @media (max-width: 990px) {
    display: block;
  }

  &.open {
    span {
      &:nth-child(1) {
        top: 6px;
        width: 0%;
        left: 50%;
        opacity: 0;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        top: 6px;
        width: 0%;
        left: 50%;
        opacity: 0;
      }
    }
  }

  span {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #fff;
    position: absolute;
    transition: 0.25s ease-in-out;
    pointer-events: none;

    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 6px;
    }

    &:nth-child(4) {
      top: 12px;
    }
  }
`;

export const MenuMobile = styled.div`
  background-color: #fff;
  position: absolute;
  width: 100%;
  top: 70px;
`;

export const MenuMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 30px;
  font-size: 1.125rem;

  a {
    color: #000;
    padding: 5px;
  }

  span {
    display: block;
    padding: 5px;

    &.active {
      color: #59A059;
    }
  }
`;

export const MenuMobileLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 15px;
  
  a {
    text-decoration: none;
    font-size: 0.875rem;
    color: #000;
  }
`;

export const MenuMobileButtonContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 30px;
`;


