exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-produtos-afv-index-tsx": () => import("./../../../src/pages/produtos/afv/index.tsx" /* webpackChunkName: "component---src-pages-produtos-afv-index-tsx" */),
  "component---src-pages-produtos-b-2-b-index-tsx": () => import("./../../../src/pages/produtos/b2b/index.tsx" /* webpackChunkName: "component---src-pages-produtos-b-2-b-index-tsx" */),
  "component---src-pages-produtos-bi-index-tsx": () => import("./../../../src/pages/produtos/bi/index.tsx" /* webpackChunkName: "component---src-pages-produtos-bi-index-tsx" */),
  "component---src-pages-produtos-erp-index-tsx": () => import("./../../../src/pages/produtos/erp/index.tsx" /* webpackChunkName: "component---src-pages-produtos-erp-index-tsx" */),
  "component---src-pages-sobre-index-tsx": () => import("./../../../src/pages/sobre/index.tsx" /* webpackChunkName: "component---src-pages-sobre-index-tsx" */)
}

