import React, { ReactNode, useEffect } from "react";
import { ToastContainer } from "react-toastify";

import { ModalProvider } from "../../context/ModalContext";

import { Footer } from "../Footer";
import { Header } from "../Header";

import { GlobalStyle } from "./LayoutStyles";

interface LayoutProps {
  children: ReactNode;
}

declare global {
  interface Window { RDStationForms: any; }
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  /* useEffect(() => {
    if (window) {
      if (window.RDStationForms) {
        new window.RDStationForms('newsletter-site-6e7d147993fb3b127e6c', 'UA-25338282-1').createForm();
      }
    }
  }, []); */

  return (
    <ModalProvider>
      <GlobalStyle />
      <Header />
      <main>{children}</main>
      {/* <div role="main" id="newsletter-site-6e7d147993fb3b127e6c" style={{display: "flex", justifyContent: "center", padding: "20px 0"}} /> */}
      <Footer />
      <ToastContainer theme="colored" />
    </ModalProvider>
  );
};

export { Layout };
