import styled from "styled-components";

interface ButtonProps {
  variant: "primary" | "secondary";
}

export const Button = styled.button<ButtonProps>`
  all: unset;
  font-size: 14px;
  color: #fff;
  background-color: #59a059;
  border: 1px solid #59a059;
  padding: 16px 30px;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease-in, border 0.3s ease-in;

  &:hover {
    background-color: transparent;
    border: ${props => props.variant === "primary" ? '1px solid #fff' : '1px solid #59a059'};
    color: ${props => props.variant === "primary" ? '#fff' : '#59a059'};
  }

  &:focus {
    box-shadow: 0 0 0 3px rgb(65, 166, 255);
  }
`;