import styled from "styled-components";

export const ModalComponent = styled.div`
  width: 95vw;
  max-width: 500px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  border-radius: 0.3rem;
  background-color: #ffff;
`;

export const ModalHeader = styled.header`
  padding: 1rem;
  display: flex;
  position: relative;
  border-bottom: 1px solid #e9ecef;

  h3 {
    flex: 1;
    margin: 0;
    font-size: 1.25rem;
    color: #4b505e;

    @media (max-width: 480px) {
      font-size: 1rem;
    }
  }

  svg {
    position: absolute;
    right: 1rem;
    top: 1rem;
    opacity: 0.5;
    cursor: pointer;
  }
`;

export const ModalContent = styled.div`
  padding: 1rem;
  max-height: 60vh;
  overflow-y: auto;
`;

export const ModalForm = styled.form``;

export const ModalGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
  gap: 25px 10px;
  margin-bottom: 25px;
  align-items: baseline;
`;

export const ModalField = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  label {
    color: #274b2b;
    margin-bottom: .5rem;
    font-size: 0.938rem;
    font-weight: 300;

    sup {
      color: red;
    }
  }

  select {
    display: inline-block;
    height: calc(2.25rem + 2px);
    padding: .375rem;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    // background: #fff url(data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E) no-repeat right .75rem center;
    background-size: 8px 10px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    // appearance: none;
  }

  input {
    display: block;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
  }

  textarea {
    display: block;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    height: 158px;
    resize: none;
  }
`;

export const ErrorMessage = styled.p`
  position: absolute;
  bottom: -18px;
  font-size: 0.75rem;
  color: red;
  margin: 0;
`;

export const ModalButtonContainer = styled.div`
  text-align: right;
  margin-top: 15px;
`;