import React, { ReactNode, useState } from "react";

export type ProductType = "erp" | "afv" | "b2b" | "bi"; 

interface ModalState {
  isOpen: boolean;
  activeProduct: ProductType;
  setModalOpen: (value: boolean) => void;
  setActiveProduct: (value: ProductType) => void;
};

const defaultState: ModalState = {
  isOpen: false,
  activeProduct: "erp",
  setActiveProduct: () => {},
  setModalOpen: () => {},
};

const ModalContext = React.createContext<ModalState>(defaultState);

const ModalProvider: React.FC<{children: ReactNode}> = ({ children }) => {
  const [isOpen, setModalOpen] = useState<boolean>(false);
  const [activeProduct, setActiveProduct] = useState<ProductType>("erp");
  
  return(
    <ModalContext.Provider value={{ isOpen, setModalOpen, activeProduct, setActiveProduct }}>
      {children}
    </ModalContext.Provider>
  )
}

export default ModalContext;
export { ModalProvider };