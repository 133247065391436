import React, { ChangeEvent, HTMLAttributes } from "react";

import { ModalField as ModalFieldComponent, ErrorMessage } from "./FormModalStyles";

interface ModalFieldProps extends React.DetailedHTMLProps<
HTMLAttributes<HTMLInputElement>,
HTMLInputElement
> {
  mask?: "phone";
  isRequired?: boolean;
  label: string;
  type?: React.HTMLInputTypeAttribute;
  id?: string;
}

const ModalField = React.forwardRef<HTMLInputElement, ModalFieldProps>(({ children, mask, type = "text", isRequired = false, id, label, onChange, ...props }, ref) => {
  const _onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (mask) {
      const value = event.currentTarget.value

      event.currentTarget.value = formatPhone(value);
    }

    if (onChange) {
      onChange(event);
    }
  };

  const formatPhone = (value: string): string => {
    value = value.replace(/\D/g, '');

    return value
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
      .replace(/(-\d{4})\d+?$/, '$1');
  }
  
  return(
    <ModalFieldComponent>
      <label htmlFor={id}>{label} {isRequired && <sup>*</sup>}</label>
      <input {...props} type={type} id={id} ref={ref} onChange={_onChange} required={isRequired} />

      {children}
    </ModalFieldComponent>
  )
});

ModalField.displayName = "ModalField";

export { ModalField };