/**
 * Esse componente foi criado para resolver o problema de integração de 
 * formulário com o RDStation que apenas reconhece formulários que estejam 
 * visíveis no DOM no carregamento da página (solicitar demonstração)
 */

import React, { ReactNode, useEffect } from "react";

import { CustomModalContainer, Overlay, CustomModalContent } from "./CustomModalStyles";

interface CustomModalProps {
  children?: ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

const CustomModal: React.FC<CustomModalProps> = ({ children, isOpen, onClose }) => {
  const requestClose = () => {
    onClose();
  }

  return(
    <CustomModalContainer className={isOpen ? 'open' : ''} onKeyDown={event => event.code === "Enter" && requestClose()}>
      <Overlay onClick={() => requestClose()} />
      <CustomModalContent>
        {children}
      </CustomModalContent>
    </CustomModalContainer>
  )
};

export { CustomModal };
